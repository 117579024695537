import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextInputHighlightComponent } from './text-input-highlight.component';
import { TextInputHighlightContainerDirective } from './text-input-highlight-container.directive';
import { TextInputElementDirective } from './text-input-element.directive';


export class TextInputHighlightModule {static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
  declarations: [
    TextInputHighlightComponent,
    TextInputHighlightContainerDirective,
    TextInputElementDirective
  ],
  imports: [CommonModule],
  exports: [
    TextInputHighlightComponent,
    TextInputHighlightContainerDirective,
    TextInputElementDirective
  ]
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
