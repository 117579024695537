import { Directive } from '@angular/core';


export class TextInputHighlightContainerDirective {static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
  selector: '[mwlTextInputHighlightContainer]',
  host: {
    '[class.text-input-highlight-container]': 'true'
  }
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
