import { Directive } from '@angular/core';


export class TextInputElementDirective {static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
  selector: 'textarea[mwlTextInputElement]',
  host: {
    '[class.text-input-element]': 'true'
  }
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
